@font-face {
  font-family: "VerdanaBold";
  src: url("../fonts/Verdana-Bold.woff2") format("woff2"),
    url("../fonts/Verdana-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "VerdanaRegular";
  src: url("../fonts/Verdana.woff2") format("woff2"),
    url("../fonts/Verdana.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "KrutiDev-010";
  src: url("../fonts/KrutiDev010.woff2") format("woff2"),
    url("../fonts/KrutiDev010.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ChanakyaUniBold";
  src: url("../fonts/ChanakyaUni-Bold.woff2") format("woff2"),
    url("../fonts/ChanakyaUni-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "ChanakyaUni";
  src: url("../fonts/ChanakyaUni.woff2") format("woff2"),
    url("../fonts/ChanakyaUni.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  background: url(../Images/header-bg-line-shade.svg) no-repeat top center;
  font-size: 16px !important;
  /* color: #3d2b31 !important; */
  font-family: "VerdanaRegular";
}

.ff-VerdanaBold {
  font-family: "VerdanaBold";
}

.ff-VerdanaRegular {
  font-family: "VerdanaRegular";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* color: #3d2b31; */
  font-family: "VerdanaBold";
}

a {
  color: #3d2b31;
  text-decoration: none;
}

a:hover,
a:focus,
a:active {
  outline: none;
}

a:hover {
  color: #ff2e12 !important;
  text-decoration: none !important;
}

.fw-700 {
  font-weight: 700;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1226px !important;
  }
}

/*----- Start Header CSS -----*/

.main-header {
  padding-top: 30px;
}

.header-logo {
  float: left;
}

.s-hari {
  color: #9a4a4a;
  font-size: 12px;
  position: absolute;
  display: inline-block;
  margin: -42px auto;
  width: 23%;
  text-align: center;
  inset: 25px 0;
  font-weight: bold;
}

.search-box {
  float: right;
  margin-top: 11px;
}

.form-group {
  display: inline-block;
}

.search-field {
  background: #fff;
  border: 1px solid #e3ded0;
  border-radius: 11px;
  padding: 9px 40px 9px 12px;
  outline: none;
  width: 295px;
  font-size: 14px;
}

.form-group img {
  position: absolute;
  right: 4px;
  top: 3px;
  cursor: pointer;
}

.search-field:focus {
  border: 1px solid #ffc52f;
}

.search-items {
  float: left;
}

.select-lang {
  cursor: pointer;
  background: linear-gradient(254.02deg, #ffdb5e 18.46%, #ffc52f 69.21%);
  background-color: #ffdb5e;
  border-radius: 11px;
  border: none;

  line-height: 19px;

  outline: none;
  margin-left: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100px;
  float: right;
}

.select-lang .nav-link {
  color: #434343;
  padding: 10px 20px;
}

.select-lang .dropdown-item {
  padding: 8px 14px;
}

.select-lang .dropdown-item.active,
.select-lang .dropdown-item:active {
  color: #434343;
  text-decoration: none;
  background-color: #ffc200;
}

.select-lang .dropdown-menu {
  padding: 0;
  min-width: 100px;
  inset: 3px auto auto 0px !important;
}

.main-header ul {
  background: linear-gradient(0deg, #ff8a00 0%, #ffc338 68.97%, #ffd857 100%);
  border-radius: 11px;
  padding: 16px;
  text-align: center;
}

.header-menu {
  margin: 30px 0 0;
  width: 100%;
  justify-content: end;
}

.header-menu ul {
  margin-bottom: 0;
}

.header-menu ul li {
  display: inline-block;
  text-align: center;
}

.header-menu ul li::after {
  content: "|";
  color: #fff;
  margin: 0 25px;
}

.header-menu ul li:last-child::after {
  content: "";
  color: transparent;
  margin: 0;
}

.header-menu ul li a {
  font-weight: 700;
  font-size: 15px;
  font-family: "VerdanaBold";
  color: #472d1e;
}

.headermenulink {
  padding: 5px 0;
  font-weight: 600;
  font-size: 15px;
}

.headermenu .active {
  color: #d11501;
  padding: 5px 0;
}

.menuicon {
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: end;
  position: absolute;
  margin-top: 5px;
  left: -40px;
}

/*----- End Header CSS -----*/

/*----- Start Footer CSS -----*/
.footer {
  background: url("../Images/footerbg.svg") no-repeat bottom center;
  padding: 85px 0 18px;
}

.footer h5 {
  font-size: 17px;
  /* margin-bottom: 35px; */
}

.footer-menu ul li {
  background: url(../Images/list-icon.svg) no-repeat left;
  padding-left: 30px;
  margin-bottom: 24px;
}

/* .download-apps {
  padding: 0 40px;
} */
.download-apps p {
  font-size: 15px;
}

.social-icons ul li {
  padding-left: 60px;
  padding-top: 5px;
  height: 36px;
  margin-bottom: 18px;
}

.fb {
  background: url("../Images/facebook.svg") no-repeat top left;
}

.twit {
  background: url("../Images/twitter.svg") no-repeat top left;
}

.insta {
  background: url("../Images/instagram.svg") no-repeat top left;
}

.utube {
  background: url("../Images/youtube.svg") no-repeat top left;
}

.border-gradient {
  /* background: linear-gradient(to right, #FFCB12, #FF8832); */
  background-image: linear-gradient(to right, #ffcb12 50%, #ffc398 70%);
  height: 2px;
  margin: 50px 0 10px;
}

.copyright {
  font-size: 13px;
}

.listActive {
  color: #ff2e12 !important;
}

.fontfamily {
  font-family: ChanakyaUni !important;
  line-height: 1.5 !important;
}

.ebooks {
  font-family: Verdana, NalandaTim, Tunga;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  color: #3f220d;
  margin: 13px 0 0;
  line-height: 18px;
}

ul.images {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 50px;
  list-style: inside;
  margin: 0;
  padding-top: 20px;
}

ul.images>li {
  position: relative;
  padding-top: 66%;
}

ul.images>li img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* height: 100%; */
  width: 100%;
  object-fit: cover;
  border-radius: 7px;

}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 999999999;
}

#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 100px;
  height: 100px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #ff8a00;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

#loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #ff8a00;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

#loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #ffd857;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.ebooks-category {
  border-radius: 6px;
  background-color: #fff8e8;
  border: 1px solid #fff;
  padding: 25px 15px;
  text-align: center;
  margin: 0 0 60px 0;
  box-shadow: 0px 0px 20px 0px #ffedc6;
  height: 325px;
}

.gst-page-content {
  margin-top: 30px;
}

.langwidth {
  width: 75px;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  margin-left: 7px;
}

.copyright {
  display: flex;
  justify-content: space-between;
}

.powerby {
  display: flex;
}

.copyrgt {
  padding: 0px;
  margin: 0px;
  display: flex;
}

.orangetxt {
  color: orange;
  padding-left: 5px;
  padding-right: 5px;
}

.socialheader {
  text-align: center;
  margin-top: 10px;
}

/*----- End Footer CSS -----*/

/* @media (min-width: 200px) and (max-width: 320px) {
  .search-field {
    width: 200px;
  }
} */
@media (min-width: 120px) and (max-width: 320px) {
  .s-hari {
    width: 30%;
  }
}

@media (min-width: 320px) and (max-width: 450px) {
  .search-field {
    width: 250px;
  }

  .custombanner {
    display: none;
  }

  .s-hari {
    width: 25%;
  }

  .search-box {
    float: none;
    /* margin: 0 20px; */
  }
}

@media (min-width: 320px) and (max-width: 576px) {
  .btntogl {
    text-align: center;
    z-index: 3;
    position: absolute;
    width: 50%;
    right: 0;
    border: none;
  }

  .navtogl {
    width: 100%;
    justify-content: end;
    display: flex;
  }

  .select-lang {
    width: 79px;
  }

  .header-menu {
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: end;
  }

  .select-lang .nav-link {
    color: #434343;
    padding: 10px 0px;
    text-align: center;
  }

  .s-hari {
    width: 28%;
  }

  .resFooter {
    display: flex;
  }

  .footer-menu ul li {
    background: url(../Images/list-icon.svg) no-repeat left;
    padding-left: 30px;
    margin-bottom: 24px;
  }

  .social-icons ul li {
    margin-left: 20px;
  }

  .list-unstyledftr {
    margin-top: 15px;
  }

  .copyright {
    display: unset;
  }

  .powerby {
    justify-content: center;
  }

  .copyrgt {
    flex-direction: column;
  }

  .orangetxt {
    padding: 0;
  }

  .header-menu {
    margin: 0;
  }

  .search-box {
    float: none;
    margin-top: 11px;
  }

  .downloadapp {
    margin-top: 12px;
  }

  .header-logo {
    float: none;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .s-hari {
    width: 23%;
  }

  .header-menu {
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: end;
  }

  .header-logo {
    float: none;
  }

  .footer-menu ul li {
    background: url(../Images/list-icon.svg) no-repeat left;
    padding-left: 30px;
    margin-bottom: 24px;
  }

  .downloadapp {
    margin-top: 12px;
    display: grid;
  }

  .copyrgt {
    flex-direction: column;
  }

  .orangetxt {
    color: orange;
    padding-left: 5px;
    padding-right: 5px;
  }

  .search-box {
    float: none;
    margin: 0 15px;
  }

  .download {
    margin-top: 12px;
    display: grid;
  }
}

@media (min-width: 150px) and (max-width: 600px) {
  .footer {
    background: url("../Images/footerbg.svg") no-repeat bottom center;
    padding: 0px 0 18px;
  }

  .orangetxt {
    color: orange;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 771px) and (max-width: 991px) {
  .download {
    margin-top: 12px;
    display: grid;
  }

  .orangetxt {
    color: orange;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .download {
    margin-top: 12px;
    display: grid;
  }

  .orangetxt {
    color: orange;
    padding: "0 5px";
  }
}

@media (min-width: 1200px) {
  .download {
    margin-top: 12px;
    display: grid;
  }

  .orangetxt {
    color: orange;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.navbar-toggler:focus,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}